<template>
  <div>
    <div class="form-group row" style="display:flex;">
      <div class="col-md-4">
        <label class="form-control-label">{{ $t('AppointmentStatus') }}</label>
        <v-select transition="" v-model="appointmentStatusId"
                  :reduce="s => s.id" label="name"
                  :options="appointmentStatusList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </div>
    </div>
    <FullCalendar ref="timeLineCalendar"
                  id="timeLineCalendar"
                  :defaultView="defaultView"
                  :plugins="plugins"
                  :schedulerLicenseKey="'GPL-My-Project-Is-Open-Source'"
                  :locale="locale"
                  :header=" {
                                left: 'prev,next,today',
                                center: 'title',
                                right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
                            }"
                  :buttonText="buttonText"
                  :custom-buttons="customButtons"
                  :minTime="minTime"
                  :maxTime="maxTime"
                  :defaultDate="TODAY"
                  :disableResizing="disableResizing"
                  :longPressDelay="longPressDelay"
                  :selectable="selectable"
                  :selectHelper="selectHelper"
                  :eventLimit="eventLimit"
                  :slotDuration="slotDuration"
                  :slotEventOverlap="slotEventOverlap"
                  :eventDurationEditable="eventDurationEditable"
                  :eventStartEditable="eventStartEditable"
                  :eventResourceEditable="eventResourceEditable"
                  :droppable="droppable"
                  :eventTimeFormat="eventTimeFormat"
                  :handleWindowResize="handleWindowResize"
                  :resourceAreaWidth="'18%'"
                  :resourceLabelText="resourceLabel"
                  :resources="lineGetResources"
                  :events="lineGetEvents"
                  @eventRender="lineEventRender"
                  @eventClick="lineEventClick"
                  @eventResize="lineEventResize"
                  @eventDrop="lineEventDrop"
                  @select="lineSelect"
                  @eventMouseLeave="lineEventMouseLeave" />
    <div id="timeLineCalendar_refresher" @click="refreshLineCalendar()" />
  </div>
</template>

<script>

  import FullCalendar from '@fullcalendar/vue'
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
  import interactionPlugin from '@fullcalendar/interaction';

  import { getDate, getDateWithHour, getHour } from '../../../utils/dateTimeExtensions'
  import { updateAppointmentConfirmation } from '../../../helpers/swalConfirmations'

  import calendarService from '../../../services/calendar'
  import commonService from '../../../services/common'
  //import FastAppointment from '../../components/appointment/FastAppointmentModalComponent.vue'
  import AppointmentDetails from '../../../components/appointment/AppointmentDetails'

  export default {
    components: {
      FullCalendar
    },
    props: {
      mode: String
    },
    data() {
      return {
        TODAY: this.$moment(this.$parent.$parent.selectedDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        plugins: [resourceTimelinePlugin, interactionPlugin],
        locale: this.$store.getters['Auth/currentLanguage'],
        defaultView: 'resourceTimelineDay',
        minTime: this.$store.getters['Calendar/calendarStartTime'],
        maxTime: this.$store.getters['Calendar/calendarEndTime'],
        buttonText: {
          today: this.$t("DateRangePicker.Today"),
          month: this.$t("Month"),
          week: this.$t("Week"),
          day: this.$t("Day"),
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false
        },
        customButtons: {
          prev: { // this overrides the prev button
            text: "prev",
            click: () => {
              let calendarApi = this.$refs.timeLineCalendar.getApi();
              calendarApi.prev();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          },
          next: { // this overrides the next button
            text: "next",
            click: () => {
              let calendarApi = this.$refs.timeLineCalendar.getApi();
              calendarApi.next();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          },
          today: { // this overrides the today button
            text: this.$t("DateRangePicker.Today"),
            click: () => {
              let calendarApi = this.$refs.timeLineCalendar.getApi();
              calendarApi.today();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          }
        },
        longPressDelay: 300,
        slotDuration: '00:15:00',
        slotEventOverlap: false,
        droppable: true, // Editleme ayar�na ba�lanmal�
        selectable: this.$store.getters['Auth/checkPermission']('appointment_new'),
        selectHelper: true,
        eventLimit: true,
        disableResizing: false,
        eventDurationEditable: this.$store.getters['Auth/checkPermission']('appointment_edit'),
        eventStartEditable: this.$store.getters['Auth/checkPermission']('appointment_edit'),
        eventResourceEditable: true,
        handleWindowResize: true,
        selectedDate: '',
        dragDropAppointmentID: null,

        appointmentStatusId: '',
        appointmentStatusList: []

      };
    },
    methods: {
      lineGetResources: function (info, successCallback) {
        if (this.mode === 's' || this.mode === 'S') {

          calendarService.getCalendarEventStaffList()
            .then(response => {
              successCallback(response);
            });
        }
        else {

          calendarService.getCalendarEventRoomList()
            .then(response => {
              successCallback(response);
            });
        }
      },
      lineGetEvents: function (info, successCallback) {

        let pureStart = getDateWithHour(info.start);
        let pureEnd = getDateWithHour(info.end);
        this.$toastr.clear();

        this.$toastr.options = {
          "positionClass": "toast-top-center",
          "progressBar": true
        }
        this.$toastr.info(this.$t("Calendars.IncomingAppointments"));

        let appointmentStatusId = this.appointmentStatusId == null ? '' : this.appointmentStatusId;

        calendarService.getCalendarEventsForColumnCalendar(pureStart, pureEnd, this.mode, appointmentStatusId, this.colorType)
          .then(response => {

            successCallback(response);

          }).finally(() => {
            this.$toastr.clear();
            this.$toastr.options = null;

            if (this.appointmentStatusList && this.appointmentStatusList.length == 0)
              this.getAppointmentStatusList();
          });
      },
      lineEventRender: function (info) {
        let element = window.$(info.el);
        element.draggable = true;

        let tooltip = null;

        if (!this.$store.getters['Settings/isMobileBrowser']) {
          element.attr(
            "data-tooltip",
            getHour(info.event.start) +
            " - " +
            getHour(info.event.end) +
            "<br />" +
            (info.event.extendedProps.customerName == null ||
              info.event.extendedProps.customerName.length == 0
              ? ""
              : info.event.extendedProps.customerName + "<br />") +
            (info.event.extendedProps.services == null ||
              info.event.extendedProps.services.length == 0
              ? ""
              : info.event.extendedProps.services + "<br />") +
            (info.event.extendedProps.staffName == null ||
              info.event.extendedProps.staffName.length == 0
              ? ""
              : info.event.extendedProps.staffName + "<br />") +
            (info.event.extendedProps.branchRoomName == null ||
              info.event.extendedProps.branchRoomName.length == 0
              ? ""
              : info.event.extendedProps.branchRoomName + "<br />") +
            (info.event.extendedProps.statusName == null ||
              info.event.extendedProps.statusName.length == 0
              ? ""
              : info.event.extendedProps.statusName + "<br />") +
            (info.event.extendedProps.notes != null &&
              info.event.extendedProps.notes.length > 0
              ? "<i class='fa fa-sticky-note'></i> " +
              info.event.extendedProps.notes
              : "")
          );

          element.on("mouseenter", function (event) {
            let tooltipContent = event.currentTarget.getAttribute("data-tooltip");

            tooltip = document.createElement("div");
            tooltip.classList.add("calendar-tooltip");
            tooltip.innerHTML = tooltipContent;

            // Yeni kod: �enti�i olu�turmak i�in bir span elementi ekle
            const notch = document.createElement("span");
            notch.classList.add("tooltip-notch");
            tooltip.appendChild(notch);

            document.body.appendChild(tooltip);

            // Yeni kod: Tooltip'in konumunu ayarla
            const elementRect = event.currentTarget.getBoundingClientRect();
            const tooltipRect = tooltip.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const topOffset = elementRect.top + scrollTop - tooltipRect.height - 10;
            // Yeni kod: Elementin geni�li�ini al
            const elementWidth = elementRect.width;
            // Yeni kod: Tooltip'in geni�li�ini al
            const tooltipWidth = tooltipRect.width;
            // Yeni kod: Elementin ortalanm�� pozisyonunu hesapla
            const leftOffset = elementRect.left + (elementWidth / 2) - (tooltipWidth / 2);

            tooltip.style.top = `${topOffset}px`;
            tooltip.style.left = `${leftOffset}px`;
          });

          element.on("mouseleave", function () {
            if (tooltip) {
              tooltip.style.visibility = "hidden";
              tooltip.style.opacity = "0";
              document.body.removeChild(tooltip);
              tooltip = null;
            }
          });
        }
      },
      lineEventClick: function (info) {
        info.jsEvent.preventDefault();

        let $this = this;
        $this.$modal.show(
          AppointmentDetails,
          { info: info },
          {
            minWidth: 270,
            width: 450
          },
          {
            'before-close': () => {
              if ($this.$route.name.includes('calendar'))
                $this.$refs.timeLineCalendar.getApi().refetchEvents();
            }
          }
        )
        return false;
      },
      lineEventResize: function (info) {

        let url = "";
        let pureStart = getDateWithHour(info.event.start);
        let pureEnd = getDateWithHour(info.event.end);

        if (this.mode == "s" || this.mode == "S") {
          if (info.event.extendedProps.isHelperStaff == null || info.event.extendedProps.isHelperStaff == 0) {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
              + "&staffId=" + info.event._def.resourceIds[0];
          }
          else {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd;
          }

        }
        else {
          url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
            + "&branchRoomId=" + info.event._def.resourceIds[0];
        }

        let $this = this;
        updateAppointmentConfirmation($this.$t("AppointmentChangedAlert"),
          function () {

            calendarService.updateAppointmentStartAndEndTime(url)
              .then(response => {
                if (response == -1) {
                  $this.$toastr.error($this.$t("SaveFailedError"));
                  $this.$refs.timeLineCalendar.getApi().refetchEvents();
                }
                else if (response == -2) {
                  $this.$toastr.info($this.$t("PleaseLoginAndTryAgain"));
                }
                else {
                  $this.$toastr.success($this.$t("Success"));
                  $this.$refs.timeLineCalendar.getApi().refetchEvents()
                }
              });
          }, function () {
            info.revert();
          });

      },
      lineEventDrop: function (info) {

        let url = "";
        let pureStart = getDateWithHour(info.event.start);
        let pureEnd = getDateWithHour(info.event.end);


        if (this.mode == "s" || this.mode == "S") {
          if (info.event.extendedProps.isHelperStaff == null || info.event.extendedProps.isHelperStaff == 0) {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
              + "&staffId=" + info.event._def.resourceIds[0];
          }
          else {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd;
          }
        }
        else {
          url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
            + "&branchRoomId=" + info.event._def.resourceIds[0];
        }

        let $this = this;
        updateAppointmentConfirmation($this.$t("AppointmentChangedAlert"),
          function () {

            calendarService.updateAppointmentStartAndEndTime(url)
              .then(response => {
                if (response == -1) {
                  $this.$toastr.error($this.$t("SaveFailedError"));
                  $this.$refs.timeLineCalendar.getApi().refetchEvents();
                }
                else if (response == -2) {
                  $this.$toastr.info($this.$t("PleaseLoginAndTryAgain"));
                }
                else {
                  $this.$toastr.success($this.$t("Success"));
                  $this.$refs.timeLineCalendar.getApi().refetchEvents();
                }
              });
          }, function () {
            info.revert();
          });

      },
      lineSelect: function (info) {
        if (this.dragDropAppointmentID && this.dragDropAppointmentID.length > 0) {

          let url = "";
          let pureStart = getDateWithHour(info.start);
          let pureEnd = getDateWithHour(info.end);

          if (this.mode == "s" || this.mode == "S") {

            if (info.resource.id != null || info.resource.id.length > 0) {
              url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + this.dragDropAppointmentID + "&startDate=" + pureStart + "&endDate=" + pureEnd
                + "&staffId=" + info.resource.id;
            }
            else {
              url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + this.dragDropAppointmentID + "&startDate=" + pureStart + "&endDate=" + pureEnd;
            }

          }
          else {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + this.dragDropAppointmentID + "&startDate=" + pureStart + "&endDate=" + pureEnd
              + "&branchRoomId=" + info.resource.id;
          }

          let $this = this;
          updateAppointmentConfirmation(this.$t("AppointmentChangedAlert"),
            function () {

              calendarService.updateAppointmentStartAndEndTime(url)
                .then(response => {
                  if (response == -1) {
                    $this.$toastr.error($this.$t("SaveFailedError"));
                    $this.$refs.timeLineCalendar.getApi().refetchEvents();
                  }
                  else if (response == -2) {
                    $this.$toastr.info($this.$t("PleaseLoginAndTryAgain"));
                  }
                  else {
                    $this.$toastr.success($this.$t("Success"));
                    $this.dragDropAppointmentID = null;
                    $this.$refs.timeLineCalendar.getApi().refetchEvents();
                  }
                });
            }, function () {
              info.revert();
              $this.dragDropAppointmentID = null;
              $this.$toastr.error($this.$t("MoveCanceled"));
            });

        }
        else {

          if (this.mode == "s" || this.mode == "S") {
            info.staffId = info.resource.id;
          }
          else {
            info.branchRoomId = info.resource.id;
          }

          if (this.$moment(info.start).hour() == 0)
            info.start = this.$moment(info.start).hour(9)

          if (this.$moment(info.end).hour() == 0)
            info.end = this.$moment(info.end).hour(10)

          info.date = getDate(info.start);
          info.start = getHour(info.start)
          info.end = getHour(info.end)

          let $this = this;
          if ($this.mode == "s" || $this.mode == "S") {

            //$this.$modal.show(
            //  FastAppointment,
            //  { forCalendar: true, date: info.date, start: info.start, end: info.end, staffId: info.staffId, calendarType: 'staff' },
            //  { height: 600 },
            //  { 'before-close': () => { $this.$refs.timeLineCalendar.getApi().refetchEvents(); } }
            //)

          }
          else {

            //$this.$modal.show(
            //  FastAppointment,
            //  { forCalendar: true, date: info.date, start: info.start, end: info.end, roomId: info.branchRoomId, calendarType: 'room' },
            //  { height: 600 },
            //  { 'before-close': () => { $this.$refs.timeLineCalendar.getApi().refetchEvents(); } }
            //)

          }
        }
      },
      lineEventMouseLeave: function () {
        let elements = document.getElementsByClassName("calendar-tooltip");
        for (var i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      },
      refreshLineCalendar: function () {
        this.$refs.timeLineCalendar.getApi().refetchEvents()
      },
      getAppointmentStatusList: function () {
        commonService.getAppointmentStatusList()
          .then(response => {
            this.appointmentStatusList = response;
          });
      },
    },
    watch: {
      //isVueModalOpen: function (val) {
      //  if (!val) {
      //    this.$refs.timeLineCalendar.getApi().refetchEvents();
      //  }
      //},
      colorType: function () {
        this.$refs.timeLineCalendar.getApi().refetchEvents();
      },
      selectedDate: {
        handler: function (val) {
          if (val) {
            let calendarApi = this.$refs.timeLineCalendar.getApi();
            if (val != this.$moment(calendarApi.getDate()).format('DD.MM.YYYY')) {
              let date = this.$moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD');
              calendarApi.gotoDate(date);
            }
          }
        },
        immediate: true
      },
      appointmentStatusId: function (val) {
        const $this = this;
        if (val !== undefined) {
          setTimeout(function () { $this.$refs.timeLineCalendar.getApi().refetchEvents(); }, 50)
        }
      },
    },
    computed: {
      resourceLabel: function () {
        return this.mode === 's' || this.mode === 'S' ? this.$t("Staff") : this.$t("Room");
      },
      //isVueModalOpen: function () {
      //  return this.$store.state.showModal;
      //},
      colorType: function () {
        return this.$store.getters['Calendar/calendarColorSource'];
      }
    },
    created: function () {
      this.$bus.$on('CalendarDragDrop', (value) => {
        this.dragDropAppointmentID = value;
      });
      this.$bus.$on('CalendarSelectedDate', (value) => {
        this.selectedDate = value;
      });
    },
    mounted: function () {
      this.$store.dispatch('Calendar/setLastSelectedCalendar', this.$route.name);
    }
  }
</script>

<style scoped>
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/timeline/main.css';
  @import '~@fullcalendar/resource-timeline/main.css';
</style>
